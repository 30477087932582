//@ts-ignore
import {
  AbstractMessageHandler,
  Message,
  MessageResponse
} from '../abstract-message-handler';
import {RingLevelService} from '../message-handler.service';

const is = require('is_js');

export class AuthServiceHandler extends AbstractMessageHandler {
  name = 'auth-service-ring-level';
  async handle(message: Message, service: RingLevelService, response: MessageResponse) {
    try {
      const authService = service.getAuthService();
      await authService.init();
    } catch (e) {
      console.log('error in Auth Service Init', e);
    }
    return super.handle(message, service, response);
  }
}
import {bind, BindingScope, inject} from '@loopback/core';

import {Message} from './abstract-message-handler';
import {
  AuthServiceHandler,
  RestServerServiceHandler,
} from './handlers';
import { app } from '../../App';

import { AuthService } from '../../services/auth-service/auth.service';
import { RestServerService } from '../../services/rest-server.service';

@bind({scope: BindingScope.SINGLETON})
export class RingLevelService {
  messageHandler: any;
  result: any;

  private static instance: RingLevelService;
  private authService: AuthService;
  private restServerService: RestServerService;

  private constructor(

  ) {
    
    this.authService = AuthService.getInstance();
    this.restServerService = RestServerService.getInstance();

    const messageHandler = new AuthServiceHandler();
    messageHandler
      .setNext(new RestServerServiceHandler())

    this.messageHandler = messageHandler;
    this.result = messageHandler.res;
  }

  static getInstance(): RingLevelService {
    if (!RingLevelService.instance) {
      RingLevelService.instance = new RingLevelService();
    }

    return RingLevelService.instance;
  }

  public async handleMessage(message: Message) {
    let response: any = {};
    if (!this.messageHandler) {
      return Promise.reject('Message handler not provided');
    }
    try {
      console.log('handleMessage')
      await this.messageHandler.handle(message, this, response);
      return response;
    } catch (err) {
      console.log(`Error in handle template Gen Messaging => ${err}`);
    }
  }

  getAuthService() {
    return this.authService;
  }

  getRestServerService() {
    return this.restServerService;
  }

}

import {useState, useEffect} from 'react';
import {AuthService} from './auth.service';
import {app} from '../../App';
export const useAuth = () => {
  const authService = AuthService.getInstance();
  return {
    signIn: authService.signIn.bind(authService),
    signOut: authService.signOut.bind(authService),
    isAuthenticated: authService.isAuthenticated.bind(authService),
    getUserProfile: authService.getUserProfile.bind(authService),
    setUserProfile: authService.setUserProfile.bind(authService),
    getAuthToken: authService.getAuthToken.bind(authService),
    setAuthToken: authService.setAuthToken.bind(authService),
    getActor: authService.getActor.bind(authService),
    setActor: authService.setActor.bind(authService),
    removeUserProfile: authService.removeUserProfile.bind(authService),
    removeActor: authService.removeActor.bind(authService),
    removeAuthToken: authService.removeAuthToken.bind(authService),
    getFieldData: authService.getFieldData.bind(authService),
    setFieldData: authService.setFieldData.bind(authService),
    removeFieldData: authService.removeFieldData.bind(authService),
    getCoverageValue: authService.getCoverageValue.bind(authService),
    setCoverageValue: authService.setCoverageValue.bind(authService),
    removeCoverageValue: authService.removeCoverageValue.bind(authService),
    getPremiumValue: authService.getPremiumValue.bind(authService),
    setPremiumValue: authService.setPremiumValue.bind(authService),
    removePremiumValue: authService.removePremiumValue.bind(authService),
    getActiveCategory: authService.getActiveCategory.bind(authService),
    setActiveCategory: authService.setActiveCategory.bind(authService),
    removeActiveCategory: authService.removeActiveCategory.bind(authService),
    getPremiumPayout: authService.getPremiumPayout.bind(authService),
    setPremiumPayout: authService.setPremiumPayout.bind(authService),
    removePremiumPayout: authService.removePremiumPayout.bind(authService),
    getCoveragePayout: authService.getCoveragePayout.bind(authService),
    setCoveragePayout: authService.setCoveragePayout.bind(authService),
    removeCoveragePayout: authService.removeCoveragePayout.bind(authService)
  };
};